<template>
    <section
        id="PoliticaPrivacidad"
    >
    <v-container>
        <div
            id="main"
            class="col-sm-12 col-top"
        >
            <div
                class="modulo-container"
            >
                <div
                    class="panel aviso-legal"
                >
                    <div
                        class="panel-heading"
                    >
                        <h1>Política de Privacidad</h1>
                    </div>
                    <div
                        class="panel-body"
                    >
                        <p>En <span class='legal_desc'>CANTDELIMAR, S.C.</span> estamos especialmente concienciados por la confidencialidad de los datos de car&aacute;cter personal que se nos facilitan a trav&eacute;s de la p&aacute;gina web, y mediante esta pol&iacute;tica, informamos al usuario, en materia de protecci&oacute;n de datos, as&iacute; como de los derechos que le asisten.</p>
                        <p>Por ello, y en virtud del Reglamento General de Protecci&oacute;n de Datos de la Uni&oacute;n Europea (RGPD), Reglamento UE 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, y en atenci&oacute;n al principio de transparencia, ponemos a su disposici&oacute;n informaci&oacute;n relevante sobre el tratamiento de datos personales, con el fin de dar a conocer en todo momento, c&oacute;mo tratamos los datos, con qu&eacute; finalidad y los derechos correspondientes.</p>
                        <h2><strong>Responsable del tratamiento:</strong></h2>
                        <p><span class='legal_desc'>CANTDELIMAR, S.C.</span> (en adelante EL RESPONSABLE),<span class='legal_desc'>CIF</span>: <span class='legal_desc'>J39739495</span>, domiciliada en <span class='legal_desc'>BARRIO SOMACUEVA, 5</span>, <span class='legal_desc'>39120</span>, <span class='legal_desc'>LIENCRES</span>, <span class='legal_desc'>Cantabria</span> y correo electr&oacute;nico: <span class='legal_desc'>carlosmarcos@cantdelimar.es</span>.</p>
                        <p>Contacto del delegado de protecci&oacute;n de datos (DPO): No se dispone de delegado, no obstante, puede contactarnos por cualquiera de los medios arriba indicados.</p>
                        <h3><strong>Obtenci&oacute;n de los datos:</strong></h3>
                        <p>EL RESPONSABLE ha obtenido los datos personales que ser&aacute;n objeto de tratamiento, a trav&eacute;s de diferentes canales:</p>
                        <p style="padding-left: 30px;">&minus; Voluntariamente facilitados por el titular de los datos, bien a trav&eacute;s de formulario web, correo electr&oacute;nico, tel&eacute;fono o contrato, para la solicitud de cualquier tipo de informaci&oacute;n o formalizaci&oacute;n y/o adquisici&oacute;n de servicios o productos.</p>
                        <p style="padding-left: 30px;">&minus; A partir de fuentes accesibles al p&uacute;blico y/o registros p&uacute;blicos, y siempre que exista inter&eacute;s leg&iacute;timo por parte de EL RESPONSABLE para ello.</p>
                        <h3><strong>Descripci&oacute;n de la finalidad del tratamiento:</strong></h3>
                        <p>El RESPONSABLE tratar&aacute; sus datos, al objeto de responder a las peticiones realizadas a trav&eacute;s del presente sitio web, adem&aacute;s, si otorga su consentimiento para el env&iacute;o de comunicaciones comerciales, promociones e informaci&oacute;n de su inter&eacute;s, se le podr&aacute; enviar informaci&oacute;n de car&aacute;cter comercial y/o publicitario por los canales de comunicaci&oacute;n habituales. No obstante, dicho consentimiento, podr&aacute; revocarlo en cualquier momento a trav&eacute;s de los medios proporcionados a tal fin.</p>
                        <p>Trataremos sus datos para la gesti&oacute;n comercial, administrativa, contable y fiscal, conforme a la normativa vigente y en funci&oacute;n de la informaci&oacute;n facilitada y autorizaci&oacute;n otorgada.</p>
                        <h3><strong>Conservaci&oacute;n de los datos</strong></h3>
                        <p>Los datos personales proporcionados se conservar&aacute;n mientras se mantenga la relaci&oacute;n con la entidad, y no se solicite su supresi&oacute;n por parte del interesado. Ser&aacute;n conservadosconforme a los plazos legales establecidos y en funci&oacute;n de la finalidad por la que fueron recabados.</p>
                        <p>En el supuesto de que el interesado est&eacute; suscrito de forma voluntaria a alg&uacute;n tipo de env&iacute;o de comunicaciones comerciales, newsletter, publicidad, etc. los datos permanecer&aacute;n hasta que &eacute;ste, tramite su baja de este tipo de comunicaciones.</p>
                        <h3><strong>Legitimizaci&oacute;n</strong></h3>
                        <p>La base por la cual, se sustenta el tratamiento de los datos de car&aacute;cter personal por parte de EL RESPONSABLE se encuentra amparada en:</p>
                        <p style="padding-left: 30px;">&minus; La mera solicitud por parte del interesado de cualquier tipo de informaci&oacute;n.</p>
                        <p style="padding-left: 30px;">&minus; Por la posible suscripci&oacute;n para recibir comunicaciones comerciales mediante consentimiento expreso.</p>
                        <p style="padding-left: 30px;">&minus; Formalizaci&oacute;n de relaci&oacute;n comercial, fruto de la adquisici&oacute;n de productos y/o servicios.</p>
                        <p style="padding-left: 30px;">&minus; Legitimaci&oacute;n por ejecuci&oacute;n de un contrato</p>
                        <h3><strong>Destinatarios</strong></h3>
                        <p>Mientras dure el tratamiento de los datos del interesado, EL RESPONSABLE no ceder&aacute; los datos a terceros, salvo que medie obligaci&oacute;n legal.</p>
                        <p>En los supuestos que, para la realizaci&oacute;n de tratamientos de tipo administrativo, fiscal, contable y/o comercial, se cuente con los servicios de terceros, se formalizar&aacute; debidamente el preceptivo contrato de encargado de tratamiento, con la finalidad de garantizar la seguridad y confidencialidad de los datos del interesado frente a terceros.</p>
                        <h3><strong>Derechos</strong></h3>
                        <p>El interesado, en relaci&oacute;n a sus datos de car&aacute;cter personal, puede ejercer los derechos siguientes de forma libre y gratuita:</p>
                        <p style="padding-left: 30px;">&minus; Derecho de acceso a sus datos personales.</p>
                        <p style="padding-left: 30px;">&minus; Derecho de rectificaci&oacute;n de sus datos personales, cuando resulten incompletos o inexactos.</p>
                        <p style="padding-left: 30px;">&minus; Derecho de supresi&oacute;n de sus datos personales, cuando no sean necesarios para los fines por los que fueron recabados.</p>
                        <p style="padding-left: 30px;">&minus; Derecho de limitaci&oacute;n de su tratamiento, de todos los datos o en parte de ellos.</p>
                        <p style="padding-left: 30px;">&minus; Derecho a oponerse al tratamiento.</p>
                        <p style="padding-left: 30px;">&minus; Derecho a la portabilidad de los datos.</p>
                        <p style="padding-left: 30px;">&minus; Derecho a retirar el consentimiento prestado previamente.</p>
                        <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protecci&oacute;n de sus datos personales, especialmente cuando no haya obtenido satisfacci&oacute;n en el ejercicio desus derechos, puede presentar una reclamaci&oacute;n ante la Autoridad de Control en materia de Protecci&oacute;n de Datos competente.</p>
                        <h3><strong>Autoridad de Control</strong></h3>
                        <p>Si usted precisa de mayor informaci&oacute;n y/o ayuda, a parte de la que nosotros podemos y deseamos proporcionarle, en materia de Protecci&oacute;n de Datos, debe saber que, la autoridad de control en Espa&ntilde;a que vela por el cumplimiento de la normativa sobre protecci&oacute;n de datos, que garantiza y tutela el derecho fundamental a la protecci&oacute;n de datos personales es:</p>
                        <p>Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos (AEPD)</p>
                        <p style="padding-left: 30px;">C/ Jorge Juan N&ordm;6<br /> 28001 &ndash; Madrid<br /> 901 100 099 &ndash; 912 663 517<br />
                            <a
                                href="https://www.aepd.es/"
                                target="_blank"
                                rel="noopener"
                            >www.agpd.es
                            </a>
                        </p>
                        <p>Pudiendo contactar tambi&eacute;n, a trav&eacute;s de este formulario.</p>
                        <p>La AEPD, informa y ayuda al ciudadano a ejercitar sus derechos, as&iacute; como tambi&eacute;n a las empresas a cumplir con las obligaciones que establece la Ley.</p>
                        <p>Como titular de sus propios datos, la AEPD tutela el ejercicio de los derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n, limitaci&oacute;n, portabilidad y oposici&oacute;n, cuando no han sido adecuadamente atendidos. Y garantiza el derecho a la protecci&oacute;n de datos, ante actuaciones que puedan ser contrarias a la ley.</p>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</section>
</template>
<script>
  export default {
    name: 'PoliticaPriv',
    metaInfo: { title: 'Política de proivacidad' },
    data: () => ({
    }),
  }
</script>
